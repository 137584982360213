import React, { useEffect, useState, useCallback } from 'react';
import { Col, Row, Spinner, Container, Modal, Alert, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import {
    useDispatchState,
    useYardsState,
    useVehicleSegmentState,
    useAccountState,
    getTripSettings,
} from '../../../../Context';
import { getYards } from '../../../../Context/actions/yardActions';
import { getDispatchDetails, updateDispatchDetails, printDispatch } from '../../../../Context/actions/dispatchActions';
import { getVehicleSegments } from '../../../../Context/actions/vehicleSegmentActions';
import { formatCurrency } from '../../../../utils/helper';
import './index2.scss';
import Dialog from '../../../../Components/Dialog/index';
import * as dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import moment from 'moment';
import { updateDispatchDetailsService } from '../../../../services/dispatchService';
dayjs.extend(utc);
dayjs.extend(timezone);

const DispatchDetails = (props) => {
    const dispatchId = props && props.match.params.id;
    const {
        dispatchDispatch,
        dispatch: { errorMessage, dispatchDetails },
    } = useDispatchState();
    const {
        vehicleSegmentDispatch,
        vehicleSegment: { segments },
    } = useVehicleSegmentState();
    const { AccountsDispatch } = useAccountState();
    const { yardsDispatch } = useYardsState();
    const [dispatchConfirmData, setDispatchConfirmData] = useState({
        showModal: false,
        showAcceptModel: false,
    });
    const [isButtonDisabled, setIsButtonDisabled] = useState(false);

    const { reset } = useForm({
        defaultValues: {
            vehicleType: '',
            vehicleId: '',
            vehiclePriceAtBooking: '',
            vehiclePrice: '',
            generalLedgerCode: '',
            driverFullName: '',
            driverStartTime: '',
            driverEndTime: '',
        },
    });
    const [loading, setLoading] = useState(false);
    const [VehicleType, setVehicleType] = useState();
    const [AssignedVehicle, setAssignedVehicle] = useState();

    useEffect(() => {
        (async function () {
            setLoading(true);
            await getTripSettings(AccountsDispatch);
            await getYards(yardsDispatch, '');
            if (dispatchId) {
                let details = await getDispatchDetails(dispatchDispatch, dispatchId);

                setVehicleType(details.vehicleDisplayName ? details.vehicleDisplayName : '');
                setAssignedVehicle(details.assignedVehicleName ? details.assignedVehicleName : '');

                await getVehicleSegments(vehicleSegmentDispatch, details.vehicleAssignmentID);
                if (details.driverStatusID === '' || Number(details.driverStatusID) === 3) {
                    let newDriverStatusID = '';
                    if (details.driverStatusID === '') newDriverStatusID = 1;
                    if (Number(details.driverStatusID) === 3) newDriverStatusID = 4;
                    await updateDispatchDetails(dispatchDispatch, dispatchId, {
                        driverStatusID: newDriverStatusID,
                    });
                    details = await getDispatchDetails(dispatchDispatch, dispatchId);
                }
                reset({
                    ...details,
                    driverStartTime: details.driverStartTime ? details.driverStartTime : null,
                    driverEndTime: details.driverEndTime ? details.driverEndTime : null,
                    vehiclePrice: `$${formatCurrency(details.vehiclePrice ? details.vehiclePrice : '0')}`,
                    vehiclePriceAtBooking: `$${formatCurrency(
                        details.vehiclePriceAtBooking ? details.vehiclePriceAtBooking : '0'
                    )}`,
                });
                await updateDispatchDetailsService(dispatchId, {
                    driverViewedAt: moment(),
                });
            } else {
                dispatchDispatch({ type: 'DISPATCHES_LIST_ERROR', payload: 'Dispatch details not found' });
            }
            setLoading(false);
        })();
    }, [dispatchId, dispatchDispatch, reset, yardsDispatch, vehicleSegmentDispatch, AccountsDispatch]);

    useEffect(() => {
        segments.sort(function (a, b) {
            let keyA = dayjs(a.departureTime),
                keyB = dayjs(b.departureTime);
            if (keyA < keyB) return -1;
            if (keyA > keyB) return 1;
            return 0;
        });
    }, [segments]);

    const checkTime = useCallback(() => {
        if (dispatchDetails) {
            if (dispatchDetails.driverStartTime) {
                const driverStart = dispatchDetails.driverStartTime.replaceAll('-', '/');
                let difference = moment(driverStart).diff(moment(), 'minutes');
                if (!dispatchDetails.checkInButtonDisableMin) {
                    setIsButtonDisabled(false);
                } else if (difference < Number(dispatchDetails.checkInButtonDisableMin)) {
                    setIsButtonDisabled(false);
                } else {
                    setIsButtonDisabled(true);
                }
            } else {
                setIsButtonDisabled(true);
            }
        }
    }, [dispatchDetails]);

    useEffect(() => {
        checkTime();
    }, [checkTime, dispatchDetails]);

    useEffect(() => {
        let interval = setInterval(checkTime, 60000);
        return () => clearInterval(interval);
    }, [checkTime]);

    const handlePrint = async () => {
        try {
            setLoading(true);

            // Safari is blocking any call to window.open() which is made inside an async call.
            // We need to set the new window before anything async to work on safari.
            let windowReference;
            const isAndroid = /(android)/i.test(navigator.userAgent);
            if (!isAndroid) {
                windowReference = window.open();
            }

            const pdfData = await printDispatch(dispatchDispatch, dispatchId);
            setLoading(false);
            let blob = new Blob([pdfData], { type: 'application/pdf' });
            let link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            if (windowReference) {
                windowReference.location = link.href;
            } else {
                window.open(link.href);
            }
        } catch (e) {
            setLoading(false);
            console.log(e);
        }
    };

    const updateDriverConfirmed = async (confirmed) => {
        setDispatchConfirmData({
            showModal: false,
            showAcceptModel: false,
        });
        let updateDispatchData = {};
        if (confirmed === 0) {
            // declined
            updateDispatchData = {
                driverDeclinedAt: new Date(),
            };
        } else {
            // accepted
            updateDispatchData = {
                driverAcceptedAt: new Date(),
            };
        }
        setLoading(true);
        await updateDispatchDetails(dispatchDispatch, dispatchId, updateDispatchData);
        let details = await getDispatchDetails(dispatchDispatch, dispatchId);
        reset({
            ...details,
            driverStartTime: details.driverStartTime ? details.driverStartTime : null,
            driverEndTime: details.driverEndTime ? details.driverEndTime : null,
            vehiclePrice: `$${formatCurrency(details.vehiclePrice ? details.vehiclePrice : '0')}`,
            vehiclePriceAtBooking: `$${formatCurrency(
                details.vehiclePriceAtBooking ? details.vehiclePriceAtBooking : '0'
            )}`,
        });
        setLoading(false);
    };

    const dispatchConfirmActions = (
        <Col xs={12} className="px-0 mt-2 ">
            <div className="d-flex mt-2">
                <div className="col-6 pl-0 pr-1">
                    <Button
                        variant="success"
                        size="lg"
                        onClick={() =>
                            setDispatchConfirmData({
                                showModal: false,
                                showAcceptModel: true,
                            })
                        }
                        className="bg-success text-white btn btn-block assignment-btn"
                    >
                        Accept Assignment
                    </Button>
                </div>
                {!Number(dispatchDetails.hideDriverPortalDeclineButton) && (
                    <div className="col-6 pl-1 pr-0">
                        <Button
                            variant="danger"
                            size="lg"
                            disabled={dispatchDetails.driverDeclinedAt}
                            onClick={() =>
                                setDispatchConfirmData({
                                    showModal: true,
                                    showAcceptModel: false,
                                })
                            }
                            className="bg-danger text-white btn btn-block btn assignment-btn"
                        >
                            Decline Assignment
                        </Button>
                    </div>
                )}
            </div>
        </Col>
    );

    const handleCheck = async (item, type) => {
        setLoading(true);
        let obj = {};
        if (type === 'checkout') {
            obj = {
                driverCheckOut: new Date(),
                driverPortalCheckOutDriverID: item.relatedDriver,
            };
        } else {
            obj = {
                driverCheckIn: new Date(),
                driverPortalCheckInDriverID: item.relatedDriver,
            };
        }
        await updateDispatchDetails(dispatchDispatch, item.id, obj);
        await getDispatchDetails(dispatchDispatch, dispatchId);
        setLoading(false);
    };

    const checkInOutRenderer = (item) => {
        if (item.driverCheckIn && !item.useDriverCheckoutTime) return;
        else if (item.driverCheckIn && item.driverCheckOut && item.useDriverCheckoutTime) return;
        else if (item.driverCheckIn && !item.driverCheckOut && item.useDriverCheckoutTime) {
            return (
                <Col xs={12} className="px-0 mt-1 ">
                    <div className="d-flex mt-2">
                        <div className="col-12 pl-0 pr-1">
                            <Button
                                variant="info"
                                size="lg"
                                className="bg-info text-white btn btn-block btn assignment-btn"
                                onClick={() => handleCheck(item, 'checkout')}
                                disabled={isButtonDisabled}
                            >
                                CHECK OUT
                            </Button>
                        </div>
                    </div>
                </Col>
            );
        } else {
            return (
                <Col xs={12} className="px-0 mt-1">
                    <div className="d-flex mt-2">
                        <div className="col-12 pl-0 pr-1">
                            <Button
                                variant="info"
                                size="lg"
                                className="bg-info text-white btn btn-block btn assignment-btn"
                                disabled={isButtonDisabled}
                                onClick={() => handleCheck(item, 'checkin')}
                            >
                                CHECK IN
                            </Button>
                        </div>
                    </div>
                </Col>
            );
        }
    };

    const getRecordSheet = async () => {
        window.open('/trip_records.pdf');
    };
    return (
        <div className="w-100 mb-3 mt-0 content-section" style={{ minHeight: 'auto' }}>
            <Row className="mx-2">
                <Col xs={12} className="px-0 mt-1 ">
                    <Link className="btn btn-secondary text-uppercase my-2 my-sm-0" to={'/driver/assignments'}>
                        <i className="fas fa-chevron-left" /> Back to Calendar
                    </Link>
                </Col>

                <Col xs={12}>
                    <b>
                        {dispatchDetails.bookingId
                            ? `Booking  ${dispatchDetails.bookingFormattedID}`
                            : dispatchDetails.scheduleServiceDescription
                            ? dispatchDetails.scheduleServiceDescription
                            : ''}
                    </b>
                </Col>

                <Col sx={12} className="px-0 mt-1">
                    <div className="col-12 head-div mt-2">
                        <div>
                            <span className="sg-card-lt">{'Basic Information'}</span>
                        </div>
                    </div>
                    {errorMessage && (
                        <Col xl={12} className="px-0 mt-2">
                            <Alert variant="danger">Dispatch Details not found</Alert>
                        </Col>
                    )}

                    <div className="d-flex">
                        <div className="col-6">
                            <div className="card-l">
                                <span className="sg font-600">{'Vehicle Type Ordered'}</span>
                            </div>
                        </div>
                        <div className="col-6 text-right">
                            <div className="card-r">
                                <span className="sg">{VehicleType}</span>
                            </div>
                        </div>
                    </div>
                    <div className="d-flex">
                        <div className="col-6">
                            <div className="card-l">
                                <span className="sg font-600">{'Assigned Vehicle'}</span>
                            </div>
                        </div>
                        <div className="col-6 text-right">
                            <div className="card-r">
                                <span className="sg">{AssignedVehicle}</span>
                            </div>
                        </div>
                    </div>
                    {dispatchDetails.displayDispatchPriceToDriver ? (
                        <>
                            {' '}
                            <div className="d-flex">
                                <div className="col-6">
                                    <div className="card-l">
                                        <span className="sg font-600">{'Dispatch Price'}</span>
                                    </div>
                                </div>
                                <div className="col-6 text-right">
                                    <div className="card-r">
                                        <span className="sg">${dispatchDetails.vehiclePrice}</span>
                                    </div>
                                </div>
                            </div>
                        </>
                    ) : (
                        ''
                    )}
                </Col>
                <Col xs={12} className="px-0 mt-1">
                    <div className="d-flex mt-2">
                        <div className="col-6">
                            <div className="card-l">
                                <span className="card-lt">{dispatchDetails.operationalContactOrganization}</span>
                                <span className="card-lb">{dispatchDetails.baseOfOperation}</span>
                            </div>
                        </div>
                        <div className="col-6 text-right pl-2">
                            <div className="card-r">
                                <span className="card-rt">
                                    <span className="ttl">start</span> {dispatchDetails.driverStartTime}
                                </span>
                                <span className="card-rb">
                                    <span className="ttl">end</span> {dispatchDetails.driverEndTime}
                                </span>
                            </div>
                        </div>
                    </div>
                </Col>
                {!dispatchDetails.driverAcceptedAt ? dispatchConfirmActions : <></>}
                {checkInOutRenderer(dispatchDetails)}
                <Col sx={12} className="px-0 mt-1">
                    <div className="col-12 head-div mt-2">
                        <div>
                            <span className="sg-card-lt">{'Route Description'}</span>
                        </div>
                    </div>
                    <div className="d-flex">
                        <div className="col-6">
                            <div className="card-l">
                                <span className="sg font-400">{dispatchDetails.routeDescription}</span>
                            </div>
                        </div>
                    </div>
                </Col>
                <Col xs={12} className="px-0 mt-2 ">
                    <div className="d-flex head-div mt-2">
                        <div className="col-8">
                            <div className="card-l">
                                <span className="sg-card-lt">{'Location'}</span>
                            </div>
                        </div>
                        <div className="col-4 text-right">
                            <div className="card-r">
                                <span className="sg-card-rt">{'Departure'}</span>
                            </div>
                        </div>
                    </div>
                    {segments.map((segment, i) => (
                        <div className="d-flex oe-list" key={i}>
                            <div className="col-8">
                                <div className="card-l">
                                    <span className="sg">{segment.startAddress}</span>
                                </div>
                            </div>
                            <div className="col-4 text-right">
                                <div className="card-r">
                                    <span className="sg">
                                        {moment(segment.departureTime, 'MM-DD-YYYY HH:mm').format('HH:mm (MM/DD)')}
                                    </span>
                                </div>
                            </div>
                        </div>
                    ))}
                </Col>
                <Col xs={12} className="px-0 mt-2 ">
                    <div className="d-flex mt-2">
                        <div className="col-6 pl-0 pr-1" style={{ display: 'inline-grid' }}>
                            <Button
                                variant=""
                                size="lg"
                                onClick={handlePrint}
                                className="bg-work-ticket text-white btn btn-block btn assignment-btn"
                            >
                                view work ticket
                            </Button>
                        </div>
                        <div className="col-6 pl-0 pr-1" style={{ display: 'inline-grid' }}>
                            <Button
                                variant=""
                                size="lg"
                                onClick={getRecordSheet}
                                className="bg-work-ticket text-white btn btn-block btn assignment-btn"
                            >
                                PRINT TRIP RECORDS SHEET
                            </Button>
                        </div>
                    </div>
                </Col>

                {dispatchDetails.pickupInstructions && (
                    <>
                        <div className="col-12 head-div mt-2">
                            <div>
                                <span className="sg-card-lt">{'Pickup Instructions'}</span>
                            </div>
                        </div>
                        <div className="col-12 ">
                            <div>
                                <div
                                    className="booking-wrap"
                                    dangerouslySetInnerHTML={{
                                        __html: dispatchDetails.pickupInstructions,
                                    }}
                                />
                            </div>
                        </div>
                    </>
                )}

                {dispatchDetails.destinationInstructions && (
                    <>
                        <div className="col-12 head-div mt-2">
                            <div>
                                <span className="sg-card-lt">{'Destination Instructions'}</span>
                            </div>
                        </div>
                        <div className="col-12 ">
                            <div>
                                <div
                                    className="booking-wrap"
                                    dangerouslySetInnerHTML={{
                                        __html: dispatchDetails.destinationInstructions,
                                    }}
                                />
                            </div>
                        </div>
                    </>
                )}

                {dispatchDetails.additionalRouteDetails && (
                    <>
                        <div className="col-12 head-div mt-2">
                            <div>
                                <span className="sg-card-lt">{'Additional Route Details'}</span>
                            </div>
                        </div>
                        <div className="col-12 ">
                            <div>
                                <div
                                    className="booking-wrap"
                                    dangerouslySetInnerHTML={{
                                        __html: dispatchDetails.additionalRouteDetails,
                                    }}
                                />
                            </div>
                        </div>
                    </>
                )}

                {dispatchDetails.additionalTripReq && (
                    <>
                        <div className="col-12 head-div mt-2">
                            <div>
                                <span className="sg-card-lt">{'Additional Trip Requirements'}</span>
                            </div>
                        </div>
                        <div className="col-12 ">
                            <div>
                                <div
                                    className="booking-wrap"
                                    dangerouslySetInnerHTML={{
                                        __html: dispatchDetails.additionalTripReq,
                                    }}
                                />
                            </div>
                        </div>
                    </>
                )}

                {dispatchDetails.driverInstructions && (
                    <>
                        <div className="col-12 head-div mt-2">
                            <div>
                                <span className="sg-card-lt">{'Driver Instructions'}</span>
                            </div>
                        </div>
                        <div className="col-12 ">
                            <div>
                                <div
                                    className="booking-wrap"
                                    dangerouslySetInnerHTML={{
                                        __html: dispatchDetails.driverInstructions,
                                    }}
                                />
                            </div>
                        </div>
                    </>
                )}
                {dispatchDetails?.otherDrivers?.length ? (
                    <Col sx={12} className="px-0 mt-1">
                        <div className="col-12 head-div mt-2">
                            <div>
                                <span className="sg-card-lt">{'OTHER DRIVER INFORMATION'}</span>
                            </div>
                        </div>
                        {dispatchDetails &&
                            dispatchDetails?.otherDrivers?.map((driver, i) => (
                                <div className="d-flex oe-list" key={i}>
                                    <div className="col-12">
                                        <div className="sg font-600">
                                            Route Description{' '}
                                            <span style={{ display: 'contents' }}>{driver.routeDescription}</span>
                                        </div>
                                        <div className="sg font-600">
                                            Driver Name{' '}
                                            <span style={{ display: 'contents' }}>{driver.driverFullName}</span>
                                        </div>
                                        <div className="sg font-600">
                                            Mobile Phone #{' '}
                                            <span style={{ display: 'contents' }}>{driver.driverMobile}</span>
                                        </div>
                                        <div className="sg font-600">
                                            Vehicle ID <span style={{ display: 'contents' }}>{driver.vehicleId}</span>
                                        </div>
                                    </div>
                                </div>
                            ))}
                    </Col>
                ) : (
                    <></>
                )}
            </Row>
            {dispatchConfirmData.showModal && (
                <Dialog
                    title={'Confirm Dispatch Decline'}
                    message={'Are you sure you wish to decline this dispatch assignment?'}
                    displayOkButton
                    okButtonText="YES"
                    okButtonClick={() => updateDriverConfirmed(0)}
                    displayCancelButton
                    cancelButtonText="NO"
                    cancelButtonClick={() =>
                        setDispatchConfirmData({
                            showModal: false,
                            showAcceptModel: false,
                        })
                    }
                    onCloseClick={() =>
                        setDispatchConfirmData({
                            showModal: false,
                            showAcceptModel: false,
                        })
                    }
                />
            )}
            {dispatchConfirmData.showAcceptModel && (
                <Dialog
                    title={'Confirm Dispatch Acceptance'}
                    message={'Are you sure you wish to accept this dispatch assignment?'}
                    displayOkButton
                    okButtonText="YES"
                    okButtonClick={() => updateDriverConfirmed(1)}
                    displayCancelButton
                    cancelButtonText="NO"
                    cancelButtonClick={() =>
                        setDispatchConfirmData({
                            showModal: false,
                            showAcceptModel: false,
                        })
                    }
                    onCloseClick={() =>
                        setDispatchConfirmData({
                            showModal: false,
                            showAcceptModel: false,
                        })
                    }
                />
            )}
            {loading && (
                <Container className="mb-5 text-center">
                    <Modal show={true} animation={false} className="loading-modal text-center">
                        <Spinner animation="border" variant="primary" />
                    </Modal>
                </Container>
            )}
        </div>
    );
};

export default DispatchDetails;
